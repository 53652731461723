import React, { useMemo } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Cell,
} from 'recharts';

const BarChartBot = ({ filteredTasks }) => {
  // Calculate Jobs, Hours, and Miles
  const data = useMemo(() => {
    let totalTimeDriven = 0;
    let totalDistanceDriven = 0;

    filteredTasks.forEach((task) => {
      let startTime = 0,
        endTime = 0;

      if (task.task_log.task_start_time !== '') {
        startTime = parseInt(task.task_log.task_start_time, 10);
      }
      if (task.task_log.task_end_time !== '') {
        endTime = parseInt(task.task_log.task_end_time, 10);
      }

      if (
        !isNaN(startTime) &&
        !isNaN(endTime) &&
        task.task_log.task_start_time !== '' &&
        task.task_log.task_end_time !== '' &&
        endTime !== 0 &&
        startTime !== 0 &&
        endTime > startTime
      ) {
        totalTimeDriven += Math.abs(endTime - startTime);
      }

      if (task.task_detail?.task_station?.length) {
        totalDistanceDriven += parseFloat(task.task_detail.task_station.length);
      }
    });

    return [
      { name: 'Jobs', value: filteredTasks.length, color: '#f4b566' }, // Orange
      {
        name: 'Hours',
        value: (totalTimeDriven / 3600).toFixed(2),
        color: '#c8a3f3',
      }, // Purple
      {
        name: 'Miles',
        value: totalDistanceDriven.toFixed(2),
        color: '#6f73f0',
      }, // Blue
    ];
  }, [filteredTasks]);

  // Custom Legend
  const renderLegend = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          paddingLeft: 20,
        }}
      >
        {data.map((entry, index) => (
          <div
            key={`legend-item-${index}`}
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: 8,
            }}
          >
            <div
              style={{
                width: 12,
                height: 12,
                backgroundColor: entry.color,
                marginRight: 8,
              }}
            ></div>
            <span>{`${entry.name}: ${entry.value}`}</span>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div style={{ display: 'flex', height: '100%', width: '100%' }}>
      <ResponsiveContainer width="80%" height="100%">
        <BarChart
          data={data}
          margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Bar dataKey="value" radius={[30, 30, 0, 0]}>
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
      <div
        style={{
          width: '20%',
          display: 'flex',
          alignItems: 'center',
          fontSize: '18px',
          fontWeight: '600',
          color: '#374151',
        }}
      >
        {renderLegend()}
      </div>
    </div>
  );
};

export default BarChartBot;
