import './App.css';
import React, { useEffect, useState } from 'react';
import MQTTClient from './setupConfig.js';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import Login from './components/pages/Login.js';
import Register from './components/pages/register.js';
import Home from './components/pages/Home.js';
import Companies from './components/pages/Companies.js';
import ThemeProvider from './components/Context/Context.js';
import PolicyPage from './components/pages/PolicyPage.js';
import ProtectedRoute from './components/protectedrout.js';
import Analytics from './components/pages/Analytics.js';
import LandingPage from './components/pages/LandingPage.js';
import Tags from './components/pages/tags.js';

// import "../server.js"
function App() {
  const [tags, setTags] = useState({});
  // useEffect(() => {
  //   const mqttClient = new MQTTClient(setTags);

  // }, []);

  return (
    <ThemeProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/policy" element={<PolicyPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/companypolicy" element={<PolicyPage />} />
          <Route path="/" element={<LandingPage />} />
          <Route path="/register" element={<Register />} />
          <Route
            path="/home"
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />
          <Route
            path="/companies"
            element={
              <ProtectedRoute>
                <Companies />
              </ProtectedRoute>
            }
          />
          <Route
            path="/analytics"
            element={
              <ProtectedRoute>
                <Analytics />
              </ProtectedRoute>
            }
          />
          <Route
            path="/tags"
            element={
              <ProtectedRoute>
                <Tags />
              </ProtectedRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
